:root {
  /** sunny side **/
  --blue-border: #72cce3;
  --blue-color: #96dcee;
  --yellow-background: #fffaa8;
  --yellow-border: #f5eb71;
  /** dark side **/
  --indigo-border: #5d6baa;
  --indigo-color: #6b7abb;
  --gray-border: #e8e8ea;
  --gray-dots: #e8e8ea;
  /** general **/
  --white: #fff;
}

.theme-dark{
  /* --secondary: #445963;
  --secondary-light: #708690;
  --secondary-dark: #1b3039;
  --secondary-text: #ffffff; */
  --dark-text: #292929;
  --light-text: #F9F8F8;  
  --dark-background: #2F4550;
  --light-background: #586F7C;
  --accent: #B8DBD9;
  --button-border: #B8DBD9;
}

.theme-light{
  /* --primary: #bcbcbc;
  --primary-light: #eeeeee;
  --primary-dark: #8c8c8c;
  --primary-text: #000000; */
  --dark-text: #292929;
  --light-text: #000000;
  /* --light-text: #5E4B56; */
  /* --dark-background: #DBE7E4; */
  /* --light-background: #EDDCD2; */
  --dark-background: #ffffff;
  --light-background: #bcbcbc;
  --accent: #DBE7E4;
  --button-border: #5E4B56;
}

@keyframes reverse {
    0% {
        left: 23px;
        width: 20px;
    }
    60% {
        left: 3px;
        width: 40px;
    }
    100% {
        left: 3px;
    }
}
    
@keyframes switch {
    0% {
        left: 3px;
    }
    60% {
        left: 3px;
        width: 40px;
    }
    100% {
        left: 23px;
        width: 20px;
    }
}

@media (max-width: 767px){
  .container--toggle {
    margin-left: 0!important;
  }
}

.toggle--checkbox {
    display: none;
}
  
.container--toggle {
    margin-left: 20px;
    align-self: center;
}

.toggle--label {
    width: 50px;
    height: 27px;
    background: var(--indigo-color);
    border-radius: 50px;
    border: 3px solid var(--indigo-border);
    display: flex;
    position: relative;
    transition: all 350ms ease-in;
    margin-bottom: 0;
}

.toggle--checkbox:checked + .toggle--label {
    background: var(--blue-color);
    border-color: var(--blue-border);
}

/* sun and moon */
.toggle--checkbox:checked + .toggle--label:before {
    animation-name: reverse;
    animation-duration: 350ms;
    animation-fill-mode: forwards;
    transition: all 360ms ease-in;
    background: var(--yellow-background);
    border-color: var(--yellow-border);
}

.toggle--label:before {
    animation-name: switch;
    animation-duration: 350ms;
    animation-fill-mode: forwards;
    content: '';
    width: 20px;
    height: 20px;
    border: 3px solid var(--gray-border);
    top: 1px;
    left: 3px;
    position: absolute;
    border-radius: 20px;
    background: white;
}

/* moon dimples */
.toggle--label:after {
    transition-delay: 0ms;
    transition: all 250ms ease-in;
    position: absolute;
    content: '';
    box-shadow: var(--gray-dots) -14px 0 0 1px, var(--gray-dots) -22px 12px 0 -1px;
    left: 20px;
    top: 5px;
    width: 2px;
    height: 2px;
    background: transparent;
    border-radius: 50%;
    opacity: 1;
}

.toggle--checkbox:checked + .toggle--label:after {
    transition-delay: 50ms;
    opacity: 0;
}

.toggle--checkbox:checked + .toggle--label .toggle--label-background {
    width: 8px;
    left: 28px;
    top: 9px;
    height: 3px;
}

.toggle--checkbox:checked + .toggle--label .toggle--label-background:before {
    top: -5px;
    left: -1px;
    width: 15px;
    height: 3px;
}

.toggle--checkbox:checked + .toggle--label .toggle--label-background:after {
    top: 5px;
    width: 15px;
    height:3px;
    left: -4px;
}

.toggle--label-background {
    border-radius: 5px;
    position: relative;
    background: white;
    left: 15px;
    width: 2px;
    transition: all 150ms ease-in;
    top: 15px;
    height: 2px;
}

.toggle--label-background:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    top: -15px;
    left: -2px;
    border-radius: 5px;
    background: white;
    transition: all 150ms ease-in;
}

.toggle--label-background:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    left: -10px;
    top: -2px;
    border-radius: 5px;
    background: white;
    transition: all 150ms ease-in;
}

.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "avenir next", avenir, -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  color: var(--light-text)!important;

  background-color: var(--dark-background)!important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  background-color: var(--dark-background);
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: "#000000";
}


@media screen and (max-width: 1200px){
  #skillComponent .skills{
    min-width: 300px!important; 
  }     
}

@media screen and (max-width: 991px){
  #skillComponent .container .row .col{
    justify-content: center;
  }     
}

.card {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  min-width: 350px!important;
  min-height: 400px!important;
}

.card-title {
  font-size: 22px;
  font-weight: 600;
  color: black!important;
}

.card-body {
  margin-top: 27px;
  margin-bottom: 2px;
  line-height: 1.5;
  font-size: 16px;
  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
}

.image-container {
  margin-top: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  @media screen and (min-width: 576px) {
    padding-bottom: 30px;
  }
}

.image-inner-container {
  width: 100%;
  margin: 0 auto;
  max-width: 250px;
}

.ratio {
  position: relative;
  width: 100%;
  height: 0;
}

.ratio-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#skill{
  background-image: linear-gradient(black, var(--dark-background));
}

#contact i,
#contact span{
  color: var(--light-text);
}

.nav-link{
  font-size: 20px;
}

.nav-link a{
  color: white!important;
}

@media screen and (max-width: 767px){
  #project h3, 
  #project p,
  #project h5{
    text-align:center!important;
  }
  #project h5{
    margin-top:50px!important;
  }
}

@media(max-width: 767px){
  .navbar .container{
  justify-content: center!important;
  } 
}
